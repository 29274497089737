<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Dashboard Geral
    </v-card-title>
    <v-card-text>
      <div class="d-flex pt-0">
        <v-spacer></v-spacer>
        <div class="table-v-action-button mr-3 pt-1" @click="doLoad()"
          :aria-label="'atualizarButton'"
        >
          <v-icon>mdi-refresh</v-icon>Atualizar
        </div>
        <year-select
          style="max-width: 170px"
          class="mb-n1 mt-0 mr-3"
          v-model="anoBase"
          :aria-label="'anoBaseSelect'"
          @change="doLoad()"
        ></year-select>
        <servico-select
          style="max-width: 250px"
          class="mb-n1 mt-0 mr-3"
          v-model="servico"
          :aria-label="'beneficioSelect'"
          @change="doLoad()"
        ></servico-select>
      </div>
    </v-card-text>

    <v-row class="m-0">
      <chart-wrap cols="6" title="Dispêndio Total Mensal">
        <bar-chart
          style="height: 280px"
          class="m-3"
          :chartdata="totalMensal.chartdata"
          :options="totalMensal.options"
        ></bar-chart>
      </chart-wrap>
      <chart-wrap cols="6" title="Dispêndio Top 10 Projetos">
        <bar-chart
          style="height: 280px"
          class="m-3"
          :chartdata="topProjetos.chartdata"
          :options="topProjetos.options"
        ></bar-chart>
      </chart-wrap>
      <chart-wrap v-if="isLeiDoBem" cols="6" title="Dispêndio com Terceiros">
        <pie-chart
          style="height: 280px; width: 100%; max-width: 500px"
          class="m-auto mt-3"
          :chartdata="terceiros.chartdata"
          :options="terceiros.options"
        ></pie-chart>
      </chart-wrap>
      <chart-wrap v-else cols="6" title="Dispêndio por Categoria">
        <pie-chart
          style="height: 280px; width: 100%; max-width: 500px"
          class="m-auto mt-3"
          :chartdata="categoria.chartdata"
          :options="categoria.options"
        ></pie-chart>
      </chart-wrap>
      <chart-wrap cols="6" title="Dispêndio Mensal por Categoria">
        <line-chart
          style="height: 280px"
          class="m-3"
          :chartdata="categoriaMensal.chartdata"
          :options="categoriaMensal.options"
        ></line-chart>
      </chart-wrap>
      <!-- TODO remover v-if após os status de projetos virem do back -->
      <chart-wrap v-if="false" cols="12" title="Status das Fichas de Projetos">
        <bar-chart
          style="height: 280px"
          class="m-3"
          :chartdata="statusProjetos.chartdata"
          :options="statusProjetos.options"
        ></bar-chart>
      </chart-wrap>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { BeneficiosEnum } from '@/core/enums/beneficios';
import toMonth from '@/helpers/toMonth';

const dispendioLeiInformatica = {
  1: "RH",
  "-4": "Equipamentos e Software",
  "-5": "Obras Civis - Terceiros",
  "-6": "Material de consumo",
  "-7": "Serviços Técnicos",
  "-8": "Treinamento",
  "-9": "Livros e Periódicos",
  "-10": "Viagens",
  "-11": "Outro Correlatos",
  "-12": "Auditoria Independente",
  "-13": "Material de protótipo",
};

export default {
  components: {
    "bar-chart": () => import("@/components/bar-chart.vue"),
    "chart-wrap": () => import("./dashboardgeral/chart-wrap.vue"),
    "line-chart": () => import("@/components/line-chart.vue"),
    "pie-chart": () => import("@/components/pie-chart.vue"),
    "servico-select": () => import("@/components/servico-select.vue"),
    "year-select": () => import("@/components/year-select.vue"),
  },
  computed: {
    ...mapGetters(['clientId']),
    barChartOptions: function () {
      return {
        elements: { line: { tension: 0.000001 } },
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        plugins: {
          filler: { propagate: false },
          "samples-filler-analyser": { target: "chart-analyser" },
        },
        responsive: true,
        spanGaps: true,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value) => {
                  return this.abbreviateNumber(value);
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$options.filters.toCurrency(tooltipItem.value);
            },
          },
        },
      };
    },
    categoria: function () {
      const data = this.relatorios?.categoria || [];
      const chartdata = {
        labels: data.map(({ item }) => this.dispendioLeiInformaticaAberto[item] || 'N/A'),
        datasets: [
          {
            backgroundColor: data.map((_, index) => this.$chartColors[index]),
            data: data.map(({ total }) => total),
            label: "Pizza",
          },
        ],
      };

      return { chartdata, options: this.pizzaChartOptions };
    },
    categoriaMensal: function () {
      const categorias = this.isLeiDoBem ? this.dispendio : this.dispendioLeiInformatica;
      const items = this.relatorios?.mensal_por_categoria || [];
      const gastos = Object.keys(categorias).reduce((prev, key) => {
        const categoriaItens = items.filter(({ item }) => item == key);

        if (categoriaItens.some(({ total }) => (total * 1) > 0)) {
          return ({
            ...prev,
            [key]: categoriaItens,
          });
        }
        
        return prev;
      }, {});
      const data = Object.entries(gastos).map(([key, values]) => ({
        dispendio: categorias[key] || 'N/A',
        competencias: values.reduce((prev, { competencia, total }) => ({ ...prev, [competencia]: total }), {}),
      }));

      const chartdata = {
        labels: data.length > 0 ? Object.keys(data[0].competencias).map((competencia) => toMonth(competencia)) : [],
        datasets: data.map(({ dispendio, competencias }, index) => ({
          backgroundColor: "rgba(53,148,233,0)",
          borderColor: this.$chartColors[index],
          data: Object.values(competencias),
          hidden: false,
          label: dispendio,
        })),
      };

      return { chartdata, options: this.lineChartOptions };
    },
    isClient: function () {
      return this.getClient().isClient;
    },
    isLeiDoBem: function () {
      return this.servico === BeneficiosEnum.LEI_DO_BEM;
    },
    lineChartOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        elements: { line: { tension: 0.000001 } },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$options.filters.toCurrency(tooltipItem.value);
            },
          },
        },
        scales: {
          yAxes: [
            {
              stacked: false,
              ticks: {
                callback: (value) => {
                  return this.abbreviateNumber(value);
                },
              },
            },
          ],
        },
        plugins: {
          filler: { propagate: false },
          "samples-filler-analyser": { target: "chart-analyser" },
        },
      };
    },
    pizzaChartOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "right",
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return this.$options.filters.toCurrency(
                data.datasets[0].data[tooltipItem.index]
              );
            },
          },
        },
      };
    },
    resource: function () {
      return this.apiResource(`/v1/dashboard/${this.clientId}`);
    },
    statusProjetos: function () {
      const data = this.relatorios?.status || [];
      const chartdata = {
        labels: data,
        datasets: data,
        /* labels: data.map(({ titulo }) => toMonth(titulo)),
          datasets: [
            {
              backgroundColor: this.data.map(
                (dl) => dl.color
              ),
              borderColor: this.data.map(
                (dl) => dl.color
              ),
              data: this.data.map((dl) => dl.qtd),
              hidden: false,
              label: "Total",
            },
          ], */
      };
      const barChartOptions = { ...this.barChartOptions };
      delete barChartOptions.tooltips;
      const options = {
        ...barChartOptions,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                min: 0,
                stepSize: 1,
                reverse: false,
                beginAtZero: true,
              },
            },
          ],
        },
      };

      return { chartdata, options };
    },
    tableColor: function () {
      return this.isClient ? "#6BB4BA" : "#81B4EA";
    },
    terceiros: function () {
      const data = this.relatorios?.terceiros || [];
      const chartdata = {
        labels: data.map(({ enquadramento }) => enquadramento || 'N/A'),
        datasets: [
          {
            backgroundColor: this.$chartColors,
            data: data.map(({ total }) => total),
            label: "Pizza",
          },
        ],
      };

      return { chartdata, options: this.pizzaChartOptions };
    },
    topProjetos: function () {
      const data = this.relatorios?.top || [];
      const chartdata = {
        labels: data.map(({ titulo }) => `${titulo}`.length <= 15 ? titulo : (`${titulo}`.slice(0, 15) + '...')),
        datasets: [
          {
            backgroundColor: this.$chartColors,
            borderColor: this.$chartColors,
            data: data.map(({ total }) => total),
            hidden: false,
            label: "Total Mensal",
          },
        ],
      };

      return { chartdata, options: this.barChartOptions };
    },
    totalMensal: function () {
      const data = this.relatorios?.total_mensal || [];
      const chartdata = {
        labels: data.map(({ competencia }) => toMonth(competencia)),
        datasets: [
          {
            backgroundColor: this.$chartColors[0],
            borderColor: this.$chartColors[0],
            data: data.map(({ total }) => total),
            hidden: false,
            label: "Total Mensal",
          },
        ],
      };

      return { chartdata, options: this.barChartOptions };
    },
  },
  methods: {
    abbreviateNumber: function (value) {
      let newValue = value;

      if (value >= 1000) {
        let suffixes = ["", " mil", " mi", " bi", " tri"];
        let suffixNum = Math.floor(("" + value).length / 3);
        let shortValue = "";

        for (let precision = 3; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          let dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );

          if (dotLessShortValue.length <= 3) {
            break;
          }
        }

        if (shortValue % 1 != 0) {
          shortValue = shortValue.toFixed(2);
        }

        let formatter = new Intl.NumberFormat("pt-BR", {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
        newValue = formatter.format(shortValue) + suffixes[suffixNum];
      }

      return newValue;
    },
    doLoad: function () {
      if (!this.servico || !this.anoBase) {
        return;
      }

      const query = `competenciaIni=${this.anoBase}-01&competenciaFim=${this.anoBase}-12&trabalho=${this.servico}`;
      this.resource.get({ query }).then((response) => {
        if (response.error) {
          this.relatorios = null;
        } else {
          this.relatorios = response;
        }
      });
    },
  },
  data: function () {
    return {
      relatorios: null,
      anoBase: null,
      servico: null,
      dispendio: {
        1: "Dedic. Excl.",
        2: "Dedic. Parcial",
        "-3": "Serv. Terceiros/Viagens",
        "-1": "Materiais Consumo",
      },
      dispendioLeiInformatica,
      dispendioLeiInformaticaAberto: {
        ...dispendioLeiInformatica,
        1: "RH Direto",
        2: "RH Indireto",
      },
    };
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>
